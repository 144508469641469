import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { TailSpin } from "react-loader-spinner";
import moment from 'moment';
import './App.css';
import pplogo from './static/pplogo.png';
import playboy from './static/playboy.jpeg';

function App() {
    const [user, setUser] = useState(undefined);
    const [profile, setProfile] = useState(undefined);
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(false);

    const appendtoStatus = (text) => {
        const dateString = moment().format('HH:mm:ss');
        setStatus(prevStatusArray => [...prevStatusArray, dateString + " " + text]);
    }

    const api_host = 'https://api.puhurinpojat.com';

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse)
        },
        onError: (error) => {
            console.log('Login Failed:', error)
            setStatus('Login failed. Please try again.')
        }
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [user]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
        setStatus([]);
        setLoading(false);
    };

    const handleOpenGate = async () => {
        if (window.confirm('Oletko varma? Portti aukeaa ihan oikeasti')) {
            setLoading(true);
            setStatus([])
            var id = await openGate(user.access_token);
            appendtoStatus('Pyynnön id: ' + id);
            pollStatus(user.access_token, id);
        }
    };
    const openGate = async (token) => {
        return axios.post(api_host + '/gate/open', {}, { headers: { 'Authorization': token } })
                .then((res) => {
                    var id = res.data.id;
                    appendtoStatus('Portin avaus aloitettu, odota hetki...');
                    return id;
                }).catch((error) => {
                    console.error('Error opening gate:', error);
                    appendtoStatus('Virhe portin avauksessa 😕:' + error);
                    setLoading(false)
                });
    };
    const pollStatus = (token, id) => {
        axios.get(api_host + '/gate/status/' + id, { headers: { 'Authorization': token } })
            .then((res) => {
                if (res.data.status === 'busy') { //Open OK
                    appendtoStatus('Portti aukeaa! 🎉');
                    setLoading(false);
                    return;
                } else if (res.data.status === 'no-answer' || res.data.status === 'canceled' || res.data.status === 'failed') { //Error
                    appendtoStatus('Portin avaaminen epäonnistui 😕, syy: ' + res.data.status);
                    setLoading(false);
                    return;
                } else { //Poll again
                    appendtoStatus('Avaaminen vielä kesken, tilanne: ' + res.data.status);
                    setTimeout(pollStatus, 2000, token, id); //cool recursion
                }
            }).catch((error) => {
                console.error('Error polling status:', error);
                appendtoStatus('Virhe statuksen haussa: ' + error);
                setLoading(false);
            });
    };

    const statusItems = status.map(s => <li key={s}>{s}</li>);

    return (
        <div className='App'>
            <div className="Bar">
                <a className="pplogo" href="https://puhurinpojat.fi">
                    <img src={pplogo} className="logoimage" alt="logo" />
                </a>
                <h2 className="headertext">PP Portinavaussysteemi</h2>
            </div>

            <br />
            <br />
            {user && profile ? (
                <div>
                    <div className="userinfobar">
                        <h3>Moro {profile.name}</h3>
                        <img className="userinfoimg" src={profile.picture} alt="user" />
                    </div>
                    <br />
                    <div>
                        {loading ? <div style={{ marginLeft: "2vw" }}><TailSpin
                            height="40"
                            width="40"
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        /></div>
                            : <div className="buttons">
                                <button className="btn" onClick={handleOpenGate}>Avaa portti</button>
                                <button className="btn" onClick={logOut}>Kirjaudu ulos</button>
                            </div>}
                    </div>
                    <br />
                    {status.length > 0 ?
                    <div style={{marginTop: "1vh", marginLeft: "2vw", marginRight: "2vw"}}>
                        <b>Loki</b>
                        <ol style={{ paddingLeft: "0", listStyle: "none", border: "2px solid black", width:"fit-content" }}>{statusItems}</ol>
                    </div>
                    : <div/>}
                </div>
            ) : (
                <button className="btn" onClick={login}>Kirjaudu PP:n tunnuksella </button>
            )}
            <br />
            <br />

            <div className="footer">
                <a href="https://fi.glosbe.com/et/fi/j%C3%A4nku">
                    <img src={playboy} className="logoimage" alt="playboy"/>
                </a>
            </div>
        </div>
    );
}
export default App;