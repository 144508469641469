import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './index.css';

// https://blog.logrocket.com/guide-adding-google-login-react-app/
const root = createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider className="root" clientId="1037828119188-8v4gdnfgtp2pl8ec1iqaf3auh06i3rf0.apps.googleusercontent.com">
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </GoogleOAuthProvider>
);